import * as Sentry from '@sentry/browser';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
} else {
  // tslint:disable-next-line
  console.warn('Sentry is disabled.')
}

import React from 'react';
import ReactDOM from 'react-dom';

import 'tachyons/css/tachyons.css';

import { App } from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
