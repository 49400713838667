import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { GreenCoffee } from './components/GreenCoffee';
import { NotFound } from './components/NotFound';

if (!process.env.REACT_APP_API_URL) {
  throw new Error('\'process.env.REACT_APP_API_URL\' is required.');
}

export function App() {
  return (
    <Router>
      <div className="sans-serif near-black w-100 h-100">
        <Helmet titleTemplate="%s | SM Inventory" />

        <Switch>
          <Route
            path="/"
            exact={true}
            render={() => <Redirect to="/green_coffee" />}
          />
          <Route path="/green_coffee" exact={true} component={GreenCoffee} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}
