import debounce from 'lodash-es/debounce';
import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { MetadataKeyId, MetadataResponseData } from '../../types';
import { fetchJSON } from '../../utils/fetch-json';
import { replaceQueryPush } from '../../utils/replace-query';
import { SearchInput } from '../SearchInput';

interface HeaderProps {
  query?: string;
  count?: number;
  inStock: boolean;
}

function getMetadataValue(
  keyId: MetadataKeyId,
  metadata?: MetadataResponseData
) {
  if (metadata && metadata.items) {
    const item = metadata.items.find(i => i.keyId === keyId);

    return item && item.value;
  }
}

export function HeaderInner(props: HeaderProps & RouteComponentProps) {
  const [query, setQuery] = useState(props.query);
  const [inStock, setInStock] = useState(props.inStock);

  useEffect(
    () => {
      if (query !== props.query) {
        props.history.replace(
          replaceQueryPush(props.location, {
            query: query || undefined,
          })
        );
      }
    },
    [query]
  );

  useEffect(
    debounce(() => {
      if (inStock !== props.inStock) {
        props.history.replace(
          replaceQueryPush(props.location, {
            in_stock: inStock ? undefined : false,
          })
        );
      }
    }, 300),
    [inStock]
  );

  const [metadata, setMetadata] = useState<MetadataResponseData | undefined>(
    undefined
  );

  async function fetchMetadata() {
    const res = await fetchJSON<MetadataResponseData>(
      `${process.env.REACT_APP_API_URL}/metadata`
    );
    setMetadata(res.parsed);
  }

  useEffect(() => {
    fetchMetadata();
  }, []);

  const lastCompletedAt = getMetadataValue(
    MetadataKeyId.greenCoffeeJobLastCompletedAt,
    metadata
  );

  return (
    <div className="flex items-center justify-between f5 bg-green white pr2">
      <div className="pl2 pv2">
        <Link className="no-underline white" to="/green_coffee">
          SM Green Coffee
        </Link>
      </div>
      <div className="flex items-center">
        {lastCompletedAt && (
          <div className="dn db-ns pv2 f6 mr3">
            <div className="pr1 dib">Last Updated:</div>
            <div className="dib">
              {new Date(lastCompletedAt).toLocaleString('en-US')}
            </div>
          </div>
        )}
        {props.count && props.count > 0 ? (
          <div className="dn db-ns pv2 f6 mr3">
            <div className="pr1 dib">Total:</div>
            <div className="dib">{props.count}</div>
          </div>
        ) : null}
        <div className="dn db-ns pv2 f6 mr3">
          <label className="pr1" htmlFor="in-stock">
            In Stock
          </label>
          <input
            type="checkbox"
            name="in-stock"
            checked={inStock}
            onChange={() => setInStock(!inStock)}
          />
        </div>
        <SearchInput value={query} onValueChange={setQuery} />
      </div>
    </div>
  );
}

export const Header = withRouter(HeaderInner);
