import debounce from 'lodash-es/debounce';
import React, { useCallback, useEffect, useState } from 'react';

import { ReactComponent as ClearIcon } from '../../assets/clear.svg';
import { useDebounce } from '../../hooks/use-debounce';

interface SearchInputProps {
  value?: string;
  onValueChange: (value?: string) => void;
}

export function SearchInput(props: SearchInputProps) {
  let input: HTMLInputElement | null;
  const [value, setValue] = useDebounce(props.value, props.onValueChange, 250);

  return (
    <div className="relative">
      <input
        ref={r => (input = r)}
        value={value}
        className="br-pill f6 pv1 ph3 bn outline-0"
        type="text"
        placeholder="Search"
        onChange={e => {
          setValue(e.target.value);
        }}
      />
      {value && (
        <button
          className="absolute pa0 bn bg-transparent outline-0 pointer"
          style={{
            width: '20px',
            right: '3px',
            top: '2px',
          }}
          onClick={() => {
            setValue('');
            if (input) {
              input.focus();
            }
          }}
        >
          <ClearIcon
            className="w-100"
            style={{
              fill: 'gray',
            }}
          />
        </button>
      )}
    </div>
  );
}
