import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import arrowIcon from '../../assets/arrow.svg';
import { SortBy, SortDirection } from '../GreenCoffee//types';
import { QueryLink } from '../QueryLink';

interface GreenCoffeeTableHeaderProps extends RouteComponentProps {
  attr: SortBy;
  children?: React.ReactNode;
  sortBy: SortBy;
  sortDirection: SortDirection;
  className?: string;
}

export function GreenCoffeeTableHeaderInner({
  attr,
  children,
  className = '',
  location,
  sortBy,
  sortDirection,
}: GreenCoffeeTableHeaderProps) {
  return (
    <th
      className={`pv1 ph2 tl fw6 ttu sticky bg-moon-gray user-select-none nowrap top-0 ${className}`}
    >
      <QueryLink
        className="db w-100 black no-underline"
        to={{
          pathname: '/green_coffee',
          query: {
            sort: attr,
            sort_direction:
              attr === sortBy
                ? sortDirection === SortDirection.Desc
                  ? SortDirection.Asc
                  : undefined
                : undefined,
          },
        }}
        replace={true}
      >
        {attr === sortBy ? (
          <div
            className="dib pr1"
            style={{
              width: '13px',
              height: '13px',
            }}
          >
            <img
              style={{
                width: '10px',
                transform: `rotate(${
                  sortDirection === SortDirection.Asc ? '90deg' : '-90deg'
                })`,
              }}
              src={arrowIcon}
            />
          </div>
        ) : null}
        {children}{' '}
      </QueryLink>
    </th>
  );
}

export const GreenCoffeeTableHeader = withRouter(GreenCoffeeTableHeaderInner);
