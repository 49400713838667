import React from 'react';

import { ReactComponent as ClearIcon } from '../../assets/clear.svg';
import { ReactComponent as LinkIcon } from '../../assets/external-link.svg';
import { formatDate } from '../../utils/date';
import { GreenCoffee } from '../GreenCoffee/types';
import { QueryLink } from '../QueryLink';
import { Score } from '../Score';

interface GreenCoffeeDetailProps {
  coffee: GreenCoffee;
}

export function GreenCoffeeDetail({ coffee }: GreenCoffeeDetailProps) {
  return (
    <div className="pa3 pb4">
      <QueryLink
        to={{
          query: {
            detail: undefined,
          },
        }}
        className="absolute pa0 bn bg-transparent outline-0 pointer"
        style={{
          width: '24px',
          right: '6px',
          top: '6px',
        }}
      >
        <ClearIcon
          className="w-100"
          style={{
            fill: 'silver',
          }}
        />
      </QueryLink>
      <div className="f3 mb2">
        {coffee.name}
        <a
          className="dib pl2"
          href={coffee.productUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkIcon
            style={{
              width: '14px',
              height: '14px',
              fill: '#AAAAAA',
            }}
          />
        </a>
      </div>
      <div className="flex justify-center">
        <table className="mb3 f6 dark-gray mr3">
          <tbody>
            <Attr label="Score">
              <Score className="ph2 br-pill lh-copy dib" score={coffee.score}>
                {coffee.score}
              </Score>
            </Attr>
            <Attr label="Availability">
              <b>{coffee.availability}</b>
            </Attr>
            <Attr label="Price">{coffee.priceText}</Attr>
            <Attr label="Origin">
              {coffee.origin}
              {coffee.region ? ` / ${coffee.region}` : ''}
            </Attr>
            <Attr label="Processing">{coffee.processing}</Attr>
            <Attr label="Drying Method">{coffee.dryingMethod}</Attr>
            <Attr label="Cultivar">{coffee.cultivar}</Attr>
          </tbody>
        </table>
        <table className="mb3 f6 dark-gray mr3">
          <tbody>
            <Attr label="Arrival Date">
              {coffee.arrivalDate
                ? new Date(coffee.arrivalDate).toLocaleDateString('en-US', {
                    month: 'long',
                    year: 'numeric',
                  })
                : ''}
            </Attr>
            <Attr label="Added At">
              {coffee.createdAt && formatDate(new Date(coffee.createdAt))}
            </Attr>
            <Attr label="Last Updated">
              {coffee.updatedAt && formatDate(new Date(coffee.updatedAt))}
            </Attr>
          </tbody>
        </table>
      </div>
      <Section title="Description" body={coffee.description} />
      <Section title="Cupping Notes" body={coffee.cuppingNotes} />
      <Section title="Farm Notes" body={coffee.farmNotes} />
    </div>
  );
}

function Attr({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) {
  return (
    <tr className="f6">
      <td className="tr fw6 silver pt1 pr3 lh-copy">{label}</td>
      <td className="pt2 truncate mw5">{children}</td>
    </tr>
  );
}

function Section({ title, body }: { title: string; body: string }) {
  return (
    <div className="mt4">
      <div className="f6 fw6 ttu mb1">{title}</div>
      <div className="dark-gray lh-copy">{body}</div>
    </div>
  );
}
