import { Omit } from '../../types';

interface GreenCoffeeWeight {
  name: string;
  price: string;
}

export interface GreenCoffeeResponseItem {
  productId: string;
  origin: string;
  region: string;
  name: string;
  productUrl: string;
  price: string;
  priceText: string;
  weights: GreenCoffeeWeight[];
  description: string;
  score: string;
  arrivalDate: string;
  /** Coffee arrival date in YYYY-MM-DD format */
  processing: string;
  lotSize: string;
  dryingMethod: string;
  cultivar: string;
  grade: string;
  appearance: string;
  roastRecommendations: string;
  type: string;
  bagSize: string;
  packaging: string;
  farmNotes: string;
  cuppingNotes: string;
  availability: string;
  createdAt: string;
  updatedAt: string;
}

export interface GreenCoffee extends GreenCoffeeResponseItem {
  stringified: string;
  status: string;
}

export interface GreenCoffeeResponseData {
  count: number;
  items: GreenCoffeeResponseItem[];
}

export enum SortDirection {
  Desc = 'desc',
  Asc = 'asc',
}

export type SortBy = keyof Omit<GreenCoffee, 'weights'>;
