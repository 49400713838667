import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export function NotFound() {
  return (
    <div className="tc mt5">
      <Helmet>
        <title>404: Not Found</title>
      </Helmet>
      <div className="f1 b">404</div>
      <div className="f3 b mb2">Not Found</div>
      <Link className="f6 b blue no-underline" to="/">
        Head Home »
      </Link>
    </div>
  );
}
