import React from 'react';

import warningIcon from '../../assets/warning.svg';

export function Error({ message }: { message: string }) {
  return (
    <div className="tc">
      <img
        src={warningIcon}
        alt="Error"
        style={{ width: '24px', height: '24px' }}
        className="mb1"
      />
      <div className="f7 ttu tracked b">{message}</div>
    </div>
  );
}
