import React from 'react';
import { scoreColor } from '../../utils/score-color';

export function Score({
  className,
  children,
  score,
}: {
  score?: string;
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={className}
      style={{
        backgroundColor: scoreColor(score),
      }}
    >
      {children}
    </div>
  );
}
