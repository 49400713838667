import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { GreenCoffee, SortBy, SortDirection } from '../GreenCoffee/types';
import { Columns } from './columns';
import { GreenCoffeeTableHeader } from './GreenCoffeeTableHeader';
import { GreenCoffeeTableRow } from './GreenCoffeeTableRow';

interface GreenCoffeeTableProps extends RouteComponentProps {
  list: GreenCoffee[];
  sortBy: SortBy;
  sortDirection: SortDirection;
}

export function GreenCoffeeTableInner({
  list,
  sortBy,
  sortDirection,
  ...routeProps
}: GreenCoffeeTableProps) {
  return (
    <table className="miw-100 f7" cellSpacing="0">
      <thead className="tl ttu">
        <tr>
          {Columns.map(c => (
            <GreenCoffeeTableHeader
              key={c.attr}
              attr={c.attr}
              className={c.className}
              sortBy={sortBy}
              sortDirection={sortDirection}
            >
              {c.headerTitle}
            </GreenCoffeeTableHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map(c => (
          <GreenCoffeeTableRow key={c.productId} coffee={c} {...routeProps} />
        ))}
      </tbody>
    </table>
  );
}

export const GreenCoffeeTable = withRouter(GreenCoffeeTableInner);
