export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export interface ListResponseData<T = {}> {
  items: T[];
  count: number;
}

export enum MetadataKeyId {
  greenCoffeeJobLastStartedAt = 'greenCoffeeJobLastStartedAt',
  greenCoffeeJobLastCompletedAt = 'greenCoffeeJobLastCompletedAt',
}

interface MetadataItem {
  keyId: MetadataKeyId;
  value: any;
}

export type MetadataResponseData = ListResponseData<MetadataItem>;
