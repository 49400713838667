// tslint:disable-next-line
import { Location } from 'history';

export function replaceQueryTo(
  location: Location,
  queryParamsObj: { [key: string]: any }
) {
  return {
    ...location,
    search: `?${replaceQueryParams(queryParamsObj)}`,
  };
}

export function replaceQueryPush(
  location: Location,
  queryParamsObj: { [key: string]: any }
) {
  return `${location.pathname}?${replaceQueryParams(queryParamsObj)}`;
}

export function replaceQueryParams(queryParamsObj: { [key: string]: any }) {
  const queryParams = new URLSearchParams(location.search);

  Object.keys(queryParamsObj).forEach(key => {
    if (queryParamsObj[key] !== undefined) {
      queryParams.set(key, queryParamsObj[key]);
    } else {
      queryParams.delete(key);
    }
  });

  return queryParams.toString();
}
