import debounce from 'lodash-es/debounce';
import { useCallback, useEffect, useState } from 'react';

export function useDebounce<T>(
  value: T,
  callback: (v: T) => void,
  wait?: number
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [local, setLocalValue] = useState(value);
  const onValueChange = useCallback(debounce(callback, wait, {}), []);

  useEffect(
    () => {
      onValueChange(local);
      return onValueChange.cancel;
    },
    [local]
  );

  return [local, setLocalValue];
}
