export interface FetchJSONError extends Error {
  response: Response;
}

export interface JSONResponse<T> extends Response {
  parsed: T;
}

export async function fetchJSON<T>(
  input: RequestInfo
): Promise<JSONResponse<T>> {
  const response = await fetch(input);

  if (!response.ok) {
    const e = new Error(
      `Fetch Error ${response.status} ${response.statusText} ${response.url}`
    ) as FetchJSONError;
    e.response = response;

    // tslint:disable-next-line
    console.warn({
      message: e.message,
      response: e.response,
    });

    throw e;
  }

  const parsed = (await response.json()) as T;
  (response as JSONResponse<T>).parsed = parsed;
  return response as JSONResponse<T>;
}
