import React from 'react';

import externalLinkIcon from '../../assets/external-link.svg';
import { formatDate } from '../../utils/date';
import { scoreColor } from '../../utils/score-color';
import { GreenCoffee, SortBy } from '../GreenCoffee/types';
import { QueryLink } from '../QueryLink';
import { Td } from './GreenCoffeeTableRow';

export const Columns: Array<{
  attr: SortBy;
  headerTitle: string;
  className?: string;
  maxWidth?: string;
  renderRowData?: (
    props: { coffee: GreenCoffee; children?: React.ReactNode }
  ) => React.ReactNode;
}> = [
  {
    attr: 'productId',
    headerTitle: 'ID',
  },
  {
    attr: 'origin',
    headerTitle: 'Origin',
  },
  {
    attr: 'status',
    headerTitle: 'Status',
    className: 'tc',
    renderRowData: ({ children }) => (
      <Td key="status" className="tc b ttu green">
        {children}
      </Td>
    ),
  },
  {
    attr: 'name',
    headerTitle: 'Name',
    renderRowData: ({ coffee }) => (
      <Td key="name">
        <a
          className="pr2"
          href={coffee.productUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              width: '9px',
              height: '9px',
            }}
            src={externalLinkIcon}
            alt="Product Link"
          />
        </a>
        {coffee.name}{' '}
      </Td>
    ),
  },
  {
    attr: 'score',
    headerTitle: 'Score',
    className: 'tc',
    renderRowData: ({ coffee }) => (
      <Td
        key="score"
        className="tc"
        style={{
          backgroundColor: scoreColor(coffee.score),
        }}
      >
        {coffee.score}
      </Td>
    ),
  },
  {
    attr: 'arrivalDate',
    headerTitle: 'Arrival Date',
    maxWidth: '100px',
  },
  {
    attr: 'createdAt',
    headerTitle: 'Added',
    renderRowData: ({ coffee }) => (
      <Td key="createdAt">
        {coffee.createdAt && formatDate(new Date(coffee.createdAt))}
      </Td>
    ),
  },
  {
    attr: 'updatedAt',
    headerTitle: 'Last Updated',
    renderRowData: ({ coffee }) => (
      <Td key="updatedAt">
        {coffee.updatedAt && formatDate(new Date(coffee.updatedAt))}
      </Td>
    ),
  },
  {
    attr: 'availability',
    headerTitle: 'Availability',
  },
  {
    attr: 'priceText',
    headerTitle: 'Price',
  },
  {
    attr: 'processing',
    headerTitle: 'Processing',
    maxWidth: '150px',
  },
  {
    attr: 'region',
    headerTitle: 'Region',
    maxWidth: '150px',
  },
  {
    attr: 'cultivar',
    headerTitle: 'Cultivar',
    maxWidth: '150px',
  },
  {
    attr: 'dryingMethod',
    headerTitle: 'Drying Method',
    maxWidth: '150px',
  },
];

export const ColumnSorters: {
  [key: string]: (a: GreenCoffee, b: GreenCoffee) => any;
} = {
  priceText: (a: GreenCoffee, b: GreenCoffee) => {
    if (!b.price) {
      return -1;
    }
    if (!a.price) {
      return 1;
    }
    return parseFloat(a.price) - parseFloat(b.price);
  },
};
