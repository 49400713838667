import React, { CSSProperties } from 'react';
import { RouteComponentProps } from 'react-router-dom';
// tslint:disable-next-line
import { Location } from 'history';

import externalLinkIcon from '../../assets/external-link.svg';

import { formatDate } from '../../utils/date';
import { scoreColor } from '../../utils/score-color';

import { replaceQueryPush } from '../../utils/replace-query';
import { GreenCoffee } from '../GreenCoffee/types';
import { QueryLink } from '../QueryLink';
import { Columns } from './columns';

export function Td({
  title,
  children,
  className = '',
  ...rest
}: {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  title?: string;
}) {
  return (
    <td
      title={title || (children ? children.toString() : undefined)}
      className={`pv1 ph2 truncate ${className}`}
      {...rest}
    >
      {children}
    </td>
  );
}

function isActiveTableRow(id: string, location: Location) {
  const regex = new RegExp(`detail=${id}`);
  return location.pathname === '/green_coffee' && regex.test(location.search);
}

interface GreenCoffeeTableRowProps extends RouteComponentProps {
  coffee: GreenCoffee;
}

export function GreenCoffeeTableRow({
  coffee,
  location,
  history,
}: GreenCoffeeTableRowProps) {
  return (
    <tr
      className={`striped--near-white hover-bg-light-gray pointer ${
        isActiveTableRow(coffee.productId, location) ? 'active-row' : ''
      }`}
      onClick={() => {
        history.push(
          replaceQueryPush(location, {
            detail: coffee.productId,
          })
        );
      }}
    >
      {Columns.map(c =>
        c.renderRowData ? (
          c.renderRowData({ coffee, children: coffee[c.attr] })
        ) : (
          <Td
            key={c.attr}
            title={coffee[c.attr]}
            style={{ maxWidth: c.maxWidth }}
          >
            {coffee[c.attr]}
          </Td>
        )
      )}
    </tr>
  );
}
