// Originally from https://stackoverflow.com/a/17267684
function hslColorPercent(percent: number, start = 10, end = 120) {
  const a = percent;
  const b = (end - start) * a;
  const c = b + start;

  return 'hsl(' + c + ', 80%, 50%, .4)';
}

/**
 * Compute a color based on a score. 83 is considered the lowest, 93+ is the highest.
 */
export function scoreColor(score?: string) {
  if (!score) {
    return '';
  }

  return hslColorPercent(Math.min((parseFloat(score) - 83) / 10), 1);
}
