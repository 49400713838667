// tslint:disable-next-line
import { LocationDescriptorObject } from 'history';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Omit } from '../../types';
import { replaceQueryParams } from '../../utils/replace-query';

interface QueryLinkPropsTo extends LocationDescriptorObject {
  query?: {};
}

interface QueryLinkProps extends Omit<LinkProps, 'to'> {
  to: QueryLinkPropsTo;
}

export function QueryLink({ to, ...rest }: QueryLinkProps) {
  const { query, ...toRest } = to;

  if (query) {
    toRest.search = replaceQueryParams(query);
  }

  return <Link to={toRest} {...rest} />;
}
