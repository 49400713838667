import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Helmet from 'react-helmet';
import { GreenCoffeeTable } from '../../components/GreenCoffeeTable';
import { Header } from '../../components/Header';
import { fetchJSON } from '../../utils/fetch-json';
import { Error } from '../Error';
import { GreenCoffeeDetail } from '../GreenCoffeeDetail';
import { ColumnSorters } from '../GreenCoffeeTable/columns';
import {
  GreenCoffee as GreenCoffeeItem,
  GreenCoffeeResponseData,
  GreenCoffeeResponseItem,
  SortBy,
  SortDirection,
} from './types';

const ENDPOINT = `${process.env.REACT_APP_API_URL}/green_coffee`;
const NEW_WINDOW = 1000 * 60 * 60 * 24 * 7;

function isNew(coffee: GreenCoffeeResponseItem) {
  const now = new Date().valueOf();
  return now - new Date(coffee.createdAt).valueOf() < NEW_WINDOW;
}

async function fetchGreenCoffeeList(
  setGreenCoffeeList: (list: GreenCoffeeItem[]) => void,
  setError: (message: string) => void
) {
  try {
    const response = await fetchJSON<GreenCoffeeResponseData>(ENDPOINT);
    setGreenCoffeeList(
      response.parsed.items.map(i => ({
        ...i,
        status: isNew(i) ? 'New' : '',
        stringified: JSON.stringify(i),
      }))
    );
  } catch (e) {
    setError('Coffee unavailable');
  }
}

function useFetchGreenCoffeeList(
  setError: (error: string) => void
): [
  GreenCoffeeItem[] | undefined,
  React.Dispatch<React.SetStateAction<GreenCoffeeItem[] | undefined>>
] {
  const [greenCoffeeList, setGreenCoffeeList] = useState<
    GreenCoffeeItem[] | undefined
  >(undefined);

  useEffect(() => {
    fetchGreenCoffeeList(setGreenCoffeeList, setError);
  }, []);

  return [greenCoffeeList, setGreenCoffeeList];
}

function useSortedGreenCoffeeList(
  list: GreenCoffeeItem[] | undefined,
  sortBy: SortBy,
  sortDirection: SortDirection
) {
  const sortByColumn = (): GreenCoffeeItem[] => {
    return list
      ? list.sort(
          ColumnSorters[sortBy] ||
            ((a, b) => {
              if (!b[sortBy]) {
                return -1;
              }

              if (!a[sortBy]) {
                return 1;
              }

              return a[sortBy].localeCompare(b[sortBy]);
            })
        )
      : [];
  };

  return useMemo(
    () => {
      const sortedList = sortByColumn();

      if (sortDirection === SortDirection.Desc) {
        sortedList.reverse();
      }

      return sortedList;
    },
    [list, sortBy, sortDirection]
  );
}

type GreenCoffeeProps = RouteComponentProps;

export function GreenCoffee(props: GreenCoffeeProps) {
  const queryParams = new URLSearchParams(props.location.search);
  const sortBy = (queryParams.get('sort') || 'createdAt') as SortBy;
  const sortDirection =
    queryParams.get('sort_direction') === SortDirection.Asc
      ? SortDirection.Asc
      : SortDirection.Desc;
  const query = (queryParams.get('query') || '') as string;
  const inStock = queryParams.get('in_stock') !== 'false';
  const detailId = queryParams.get('detail');

  const [error, setError] = useState<string | undefined>(undefined);
  const [all] = useFetchGreenCoffeeList(setError);
  const sortedList = useSortedGreenCoffeeList(all, sortBy, sortDirection);
  const list = useMemo(
    () => {
      const regex = new RegExp(query, 'i');
      const stockRegex = new RegExp('Out of stock', 'i');
      return sortedList
        ? sortedList.filter(v => {
            const matchesQuery = query ? regex.test(v.stringified) : true;
            const stockFilter = inStock
              ? !stockRegex.test(v.availability)
              : true;
            return matchesQuery && stockFilter;
          })
        : undefined;
    },
    [all, query, sortBy, sortDirection, inStock]
  );
  const detailCoffee = useMemo(
    () => {
      return detailId && all
        ? all.find(c => c.productId === detailId)
        : undefined;
    },
    [all, detailId]
  );

  return (
    <div className="flex flex-column w-100 h-100">
      <Helmet
        title={`Green Coffee${queryParams.get('query') ? ` "${query}"` : ''}`}
      />
      <div className="flex-shrink-0">
        <Header count={list && list.length} query={query} inStock={inStock} />
      </div>
      <div className="flex relative flex-auto">
        <div className="flex-auto overflow-scroll">
          {list && list.length > 0 ? (
            <GreenCoffeeTable
              list={list}
              sortBy={sortBy}
              sortDirection={sortDirection}
            />
          ) : (
            <div className="gray pa4 f6 tc">
              {error ? <Error message={error} /> : 'Loading…'}
            </div>
          )}
        </div>
        {detailCoffee && (
          <div className="flex-auto overflow-scroll flex-shrink-0 w-100 w-40-ns bl b--black-30">
            <GreenCoffeeDetail coffee={detailCoffee} />
          </div>
        )}
      </div>
    </div>
  );
}
